body {
  margin: 0;
  background-color: #f2f2f2;
  font-family: "SF Pro Rounded", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

.veganLabel {
  color: #a58345;
}

.shop .veganLabel {
  padding-bottom: 10px;
  position: absolute;
  top: 20px;
  right: 0;
  padding: 3px 7px;
  background-color: #bb956eae;
  backdrop-filter: blur(10px);
  color: #fff;
  border-radius: 15px 0 0 15px;
}
.shop .veganLabel p {
  font-size: 0.75rem;
}

.shop {
  text-align: center;
  background: #fff;
  margin: 10px;
  position: relative;
  border-radius: 15px;
  transition: shadow 0.6s ease-in-out;
  box-shadow: 0 4px 20px -18px #000, 0 1px 1px -1px rgba(0, 0, 0, 0.5);
}

.shop .background {
  border-radius: 15px 15px 0 0;
}
.shop:hover {
  box-shadow: 0 6px 22px -18px #000, 0 1px 2px -1px rgba(0, 0, 0, 0.4);
}
.shop a {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.shop > div.background {
  padding: 50% 50% 17%;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center center;
  transition: background 0.5s ease-in-out;
}
.shop:hover > div.background {
  background-size: 105% auto;
}

footer {
  margin-top: 20px;
  padding: 5vh;
  background: #c38c49;
  color: #fff;
}
footer a.MuiTypography-colorPrimary {
  color: inherit;
}

.shop h3 {
  padding: 10px;
  margin: 0;
}
header {
  padding: 20px;
  background-color: #fff;
  margin-bottom: 15px;
}
header a {
  color: inherit;
  text-decoration: none;
}
header .MuiTypography-h1 {
  font-size: 60px;
}
